import React, { useCallback, useRef, useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

import Input from './Input';
import LanguageSelect from './LanguageSelect';
import MessageList from './MessageList';

import 'react-chat-elements/dist/main.css'
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';

const translateMessage = async (message, toLang) => {
  if (toLang === 'US') {
    toLang = 'EN';
  }

  if (toLang === 'UA') {
    toLang = 'UK';
  }

  if (toLang === 'CN') {
    toLang = 'ZH';
  }

  if (toLang === 'DK') {
    toLang = 'DA';
  }

  if (toLang === 'JP') {
    toLang = 'JA';
  }
  const endPoint = 'https://translator.houndbytes.io/translate';
  const url = new URL(endPoint);
  url.searchParams.append('text', message);
  url.searchParams.append('tolang', toLang);
  try {
    const response = await fetch(url);
    const data = await response.json();
    return data.translatedText || message;
  } catch (error) {
    console.error(error);
    return message;
  }
}

var messageIdx = 1;
var breakpoint = 768;

function App() {
  const [firstLanguageSelect, setSelectFirstLanguage] = useState("US");
  const [secondLanguageSelect, setSelectSecondLanguage] = useState("FR");
  const onSelectFirstLanguage = (code) => setSelectFirstLanguage(code);
  const onSelectSecondLanguage = (code) => setSelectSecondLanguage(code);
  const [messagesSent, setMessagesSent] = useState([]);
  const [messagesReceived, setMessagesReceived] = useState([]);
  const messageInputUserOneRef = useRef();
  const messageInputUserTwoRef = useRef();
  const messagesEndRefUserOne = useRef(null);
  const messagesEndRefUserTwo = useRef(null);

  let navigate = useNavigate(); 
  const routeChange = () => { 
    let path = `benefits`; 
    navigate(path);
  }
  const windowSize = useRef([window.innerWidth, window.innerHeight]);

  const onMessageSent = useCallback(async () => {
    const [width, height] = windowSize.current;
    let isMobile = width < breakpoint;
    const targetLanguage = secondLanguageSelect;
    const messageText = messageInputUserOneRef.current?.value || '';
    if (messageText.trim() === '') {
        return;
    }
    const id  = messageIdx++;
    const original = messageText;
    const source = 'fromLeft';
    const translatedText = await translateMessage(messageText, targetLanguage);
    if (isMobile) {
      setMessagesSent(prevMessages => [...prevMessages, {id, original, source, message: translatedText}]);
    } else {
      setMessagesSent(prevMessages => [...prevMessages, {id, original, source, message: messageText}]);
    }
    if (translatedText !== null) {
      setMessagesReceived(prevMessages => [...prevMessages, {id, original, source, message: translatedText}]);
    }
    messageInputUserOneRef.current.value = '';
    messageInputUserOneRef.current.focus();
  }, [secondLanguageSelect, firstLanguageSelect, messageIdx]);

  const onMessageReceived = useCallback(async () => {
    const messageText = messageInputUserTwoRef.current?.value || '';
    if (messageText.trim() === '') {
      return;
    }
    const id  = messageIdx++;
    const original = messageText;
    const source = 'fromRight';
    setMessagesReceived(prevMessages => [...prevMessages, {id, original, source, message: messageText}]);
    const translatedText = await translateMessage(messageText, firstLanguageSelect);
    if (translatedText !== null) {
      setMessagesSent(prevMessages => [...prevMessages, {id, original, source, message: translatedText}]);
    }
    messageInputUserTwoRef.current.value = '';
    messageInputUserTwoRef.current.focus();
  }, [firstLanguageSelect, messageIdx]);

  useEffect(() => { // translate into new target language
    let canceled = false;
    messagesSent.filter(message => message.source === 'fromRight').forEach(async (message) => {
        const id = message.id;
        const translatedText = await translateMessage(message.original, firstLanguageSelect);
        if (translatedText !== null && !canceled) {
          setMessagesSent(messagesSent => messagesSent.map(message => message.id === id ? ({...message, message: translatedText}) : message));
        }
    });
    return () => {
      canceled = true;
    };
  }, [firstLanguageSelect]);

  useEffect(() => { // translate into new target language
    let canceled = false;
    messagesReceived.filter(message => message.source === 'fromLeft').forEach(async (message) => {
      const id = message.id;
      const translatedText = await translateMessage(message.original, secondLanguageSelect);
      if (translatedText !== null && !canceled) {
        setMessagesReceived(messagesReceived => messagesReceived.map(message => message.id === id ? ({...message, message: translatedText}) : message));
      }
    });
    return () => {
      canceled = true;
    };
  }, [secondLanguageSelect]);

    useEffect(() => {
        let timer = setTimeout(() => {
            if (messagesEndRefUserOne.current) {
                messagesEndRefUserOne.current.scrollTop = messagesEndRefUserOne.current.scrollHeight;
            }
        }, 100);
        return () => clearTimeout(timer);
    }, [messagesSent]);

    useEffect(() => {
        let timer = setTimeout(() => {
            if (messagesEndRefUserTwo.current) {
                messagesEndRefUserTwo.current.scrollTop = messagesEndRefUserTwo.current.scrollHeight;
            }
        }, 100);
        return () => clearTimeout(timer);
    }, [messagesReceived]);

  return (
    <div className="App">
      <header className="App-header">
        <span className="borderDecoration"></span>
        <h1 className="headerTitle">Adult Chat Translate</h1>
        <button type="button" className="btn btn-primary btn-purple btn-lg"  onClick={routeChange}>
          Benefits
        </button>
      </header>

      {/*<h2>Width: {windowSize.current[0]}</h2>*/}

      {/*<h2>Height: {windowSize.current[1]}</h2>*/}

      <div className="contentBody">
        <div className="messageForm original">
          <div className="messageWrapper">
            <div className="messageList" ref={messagesEndRefUserOne}>
              <MessageList messages={messagesSent} type="left" />
            </div>
            <Input onMessageSent={onMessageSent} messageRef={messageInputUserOneRef} />
          </div>
          <LanguageSelect className={'noMobile'} selectedLanguage={firstLanguageSelect} onSelectLanguage={onSelectFirstLanguage} />
          <LanguageSelect className={'noDesktop'} selectedLanguage={secondLanguageSelect} onSelectLanguage={onSelectSecondLanguage} title={'Language'} />
        </div>

        <div className="messageForm translated noMobile">
          <div className="messageWrapper">
            <div className="messageList" ref={messagesEndRefUserTwo}>
              <MessageList messages={messagesReceived} type="right" />
            </div>
            <Input onMessageSent={onMessageReceived} messageRef={messageInputUserTwoRef} />
          </div>
          <LanguageSelect selectedLanguage={secondLanguageSelect} onSelectLanguage={onSelectSecondLanguage} />
        </div>
      </div>
    </div>
  );
}

export default App;
