import React from 'react';

import 'react-chat-elements/dist/main.css'
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import { Link } from 'react-router-dom';


function ChromeExtension() {

    return (
        <div className="ChromeExtension">
            <header className="App-header">
                <span className="borderDecoration"></span>
                <h1 className="headerTitle benefitsTitle"><Link className={'simpleLink'} to={'/'}>Adult Chat Translate</Link></h1>
                <Link className={'backToDemo'} to={'/'}>Back To Demo<span className='backButton'>X</span></Link>
            </header>
            <div className="container">
                <div className="row justify-content-center">
                    <h2 className='benefitsSubtitle'>Chrome Extension</h2>

                    <div className="col-12">
                        <span className={'benefitHeader'}>1. Download the chrome extension</span>
                        <p className={'benefitBody'}>The extension can be found <a href={'/download/adultchattranslate-extension.zip'}>here</a></p>
                    </div>

                    <div className=" col-12">
                        <span className={'benefitHeader'}>2. Open the Extensions page in Chrome</span>
                        <p className={'benefitBody'}>
                            To install a Chrome extension, you'll need to open the Extensions page in Chrome. To do this, click the three dots in the top right corner of Chrome and select "More Tools" > "Extensions".
                            Alternatively, you can type "chrome://extensions" into the address bar and press enter.
                        </p>
                    </div>

                    <div className=" col-12">
                        <span className={'benefitHeader'}>3. Enable Developer Mode</span>
                        <p className={'benefitBody'}>
                            On the Extensions page, you'll see a toggle switch labeled "Developer mode" in the top right corner. Toggle this switch on to enable Developer mode.
                        </p>
                    </div>

                    <div className="  col-12">
                        <span className={'benefitHeader'}>4. Load the extension</span>
                        <p className={'benefitBody'}>
                            Click the "Load unpacked" button in the top left corner of the Extensions page. This will open a file browser window. Navigate to the directory where you downloaded the extension's files and select the folder containing the extension's source code.
                        </p>
                    </div>

                    <div className=" col-12">
                        <span className={'benefitHeader'}>5. Confirm installation</span>
                        <p className={'benefitBody'}>
                            After you select the folder containing the extension's source code, Chrome will install the extension and display it in the Extensions page. You should now see the extension's icon in the top right corner of Chrome.
                        </p>
                    </div>

                    <div className=" col-12">
                        <span className={'benefitHeader'}>6. See it in action</span>
                        <p className={'benefitBody'}>
                            Visit one of the supported sites (
                            <a href={'https://stripchat.com/'} target={'_blank'} rel="noreferrer" >stripchat.com</a>,
                            &nbsp;<a href={'https://chaturbate.com/'} target={'_blank'} rel="noreferrer" >chaturbate.com</a>,
                            &nbsp;<a href={'https://livejasmin.com/'} target={'_blank'} rel="noreferrer" >livejasmin.com</a>,
                            &nbsp;<a href={'https://amateur.tv/'} target={'_blank'} rel="noreferrer" >amateur.tv</a>,
                            &nbsp;<a href={'https://warehouse-x.io/'} target={'_blank'} rel="noreferrer" >warehouse-x.io</a>
                            ) and start chatting. If you are chatting with someone who speaks a different language, the chat will be automatically translated.
                        </p>
                    </div>

                </div>

            </div>

        </div>
        //
        //     {/*Securitatea informațiilor. Garantarea securității mesajelor.*/}
        //

    );

}

export default ChromeExtension;
