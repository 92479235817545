import React, {useEffect} from 'react';

import 'react-chat-elements/dist/main.css'
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import {Link} from 'react-router-dom';


function Benefits() {

    const [formSent, setFormSent] = React.useState(false);
    const [data, setData] = React.useState({});
    const [error, setError] = React.useState('');

    const register = async (event) => {
        const {email, first_name, last_name, company} = data;
        const payload = {  email, name: first_name + ' ' + last_name, company }
        if (!email) {
            setError('Please fill in at least the email address');
            return;
        }

        fetch('https://translator.houndbytes.io/api/register', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        }).then((response) => {
            if (response.status === 200) {
                setFormSent(true);
                setError(false)
            } else {
                setError('Something went wrong, please try again later');
            }
        }).catch((error) => {
            setError('Something went wrong, please try again later');
        })
    }
    const onInputChanged = (event) => {
        console.log('onInputChanged', event.target);
        const {name, value} = event.target;
        setData({...data, [name]: value});
    }
    useEffect(() => {
        console.log('data', data);
    }, [data]);

    return (
        <div className="ChromeExtension benefitsWrapper">
            <header className="App-header">
                <span className="borderDecoration"></span>
                <h1 className="headerTitle benefitsTitle"><Link className={'simpleLink'} to={'/'}>Adult Chat Translate</Link></h1>
                <Link className={'backToDemo'} to={'/'}>Back To Demo<span className='backButton'>X</span></Link>
            </header>
            <div className="container">
                <div className="row justify-content-center">
                    <h2 className="benefitsSubtitle">Benefits of using Chat Translate</h2>

                    <div className="col-lg-6 col-12 text-start" style={{paddingBottom: '40px',}}>
                        <span className="borderDecorationSubtitle"></span>
                        <span className={'benefitHeader'}>Easy Implementation</span>
                        <p className={'benefitBody'}>Experience a seamless integration process. Our app is user-friendly, making it effortless to
                            implement within your existing chat systems. Get started quickly and unlock the power of multilingual communication.</p>
                        <button type="button" className="btn btn-primary btn-purple btn-lg">
                            <a className={'simpleLink'} href={"#contactForm"} >
                                Wanna test it?
                            </a>
                        </button>
                    </div>


                    <div className="col-lg-6 col-12 text-start" style={{paddingBottom: '40px',}}>
                        <span className="borderDecorationSubtitle"></span>
                        <span className={'benefitHeader'}>Administrative Dashboard</span>
                        <p className={'benefitBody'}>Access a comprehensive administrative dashboard that provides valuable insights. Monitor
                            important data such as character usage, volumes by hour, minute, day, and more. Make informed decisions based on real-time
                            metrics.</p>
                        <button type="button" className="btn btn-primary btn-purple btn-lg">
                            <a className={'simpleLink'} target={'_blank'} rel="noreferrer" href={'https://translator.houndbytes.io/dashboard'}>See
                                it in action</a>
                        </button>
                    </div>

                    <div className="col-lg-6 col-12 text-start">
                        <span className="borderDecorationSubtitle"></span>
                        <span className={'benefitHeader'}>Cost Savings</span>
                        <p className={'benefitBody'}>Save significantly on translation costs compared to other automated tools like Google Translate
                            or Amazon services. Our app is designed to be more affordable while maintaining excellent translation quality.</p>
                    </div>

                    <div className="col-lg-6 col-12 text-start">
                        <span className="borderDecorationSubtitle"></span>
                        <span className={'benefitHeader'}>Performance Monitoring</span>
                        <p className={'benefitBody'}>Track and evaluate the performance of our app in real-time. Gain insights into translation
                            accuracy, identify areas for improvement, and ensure optimal performance at all times.</p>
                    </div>

                    <div className="col-lg-6 col-12 text-start">
                        <span className="borderDecorationSubtitle"></span>
                        <span className={'benefitHeader'}>Scalability and Flexibility</span>
                        <p className={'benefitBody'}>Our app is designed to scale effortlessly with your growing needs. Handle increased translation
                            volumes without compromising speed or quality. Enjoy the flexibility to adapt to evolving industry requirements.</p>
                    </div>

                    <div className="col-lg-6 col-12 text-start">
                        <span className="borderDecorationSubtitle"></span>
                        <span className={'benefitHeader'}>Streamlined Communication</span>
                        <p className={'benefitBody'}>Break down language barriers and enable smooth communication between individuals. Our app ensures
                            a seamless experience, facilitating engaging conversations across languages. Connect with a broader audience and foster
                            inclusivity.</p>
                    </div>

                    <div className="col-lg-6 col-12 text-start">
                        <span className="borderDecorationSubtitle"></span>
                        <span className={'benefitHeader'}>Improved Customer Satisfaction</span>
                        <p className={'benefitBody'}>Enhance the customer experience within the adult industry. By eliminating language obstacles, our
                            app enables better engagement and satisfaction. Connect with a diverse user base, leading to increased customer loyalty
                            and growth.</p>
                    </div>

                    <div className="col-lg-6 col-12 text-start">
                        <span className="borderDecorationSubtitle"></span>
                        <span className={'benefitHeader'}>Google Chrome Extension</span>
                        <p className={'benefitBody'}>Easy to use Chrome Extension to translate your favourite sites.</p>
                        <button type="button" className="btn btn-primary btn-purple btn-lg">
                            <a className={'simpleLink'} target={'_blank'} rel="noreferrer" href={'/chromeExtension'}>Check Chrome extension</a>
                        </button>
                    </div>

                    <div className=" col-12">
                        {/*<br/>*/}
                        {/*<br/>*/}
                        {!formSent && <form className={'contactForm'}>

                            <div className={'spacer'}></div>
                            <div className="col-md-12 form-floating" >
                                <h2>
                                    Wanna test it?
                                </h2>
                                <h2>
                                    Leave your email and we will contact you.
                                </h2>
                            </div>

                            <div className="col-md-12 form-floating">
                                <input className="form-control" placeholder="E-mail" type="text" name="email" id="email" onChange={onInputChanged}/>
                                <label className="form-label" htmlFor="email">Email</label>
                            </div>

                            <div className="col-md-12 form-floating">
                                <input className="form-control" placeholder="First name" type="text" name="first_name" id="first_name" onChange={onInputChanged}/>
                                <label className="form-label" htmlFor="first_name">First name</label>
                            </div>

                            <div className="col-md-12 form-floating">
                                <input className="form-control" placeholder="Last name" type="text" name="last_name" id="last_name" onChange={onInputChanged}/>
                                <label className="form-label" htmlFor="last_name">Last name</label>
                            </div>

                            <div className="col-md-12 form-floating">
                                <input className="form-control" placeholder="Company" type="text" name="company" id="company" onChange={onInputChanged}/>
                                <label className="form-label" htmlFor="company">Company name</label>
                            </div>


                            <div className="col-12" >
                                <input type="button" name="commit" value="Let's talk" className="btn btn-purple" data-disable-with="Let's talk" onClick={register}/>
                            </div>
                            <a id={'contactForm'}></a>
                        </form>}
                        {formSent && <div>
                            <h4>Thank you for your interest in our product. We will get back to you as soon as possible.</h4>
                        </div>}
                        {error && <div>
                            <h5>{error}</h5>
                        </div>}
                    </div>

                    <div style={{padding: '50px',}}></div>

                </div>


            </div>


            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
        </div>
        //
        //     {/*Securitatea informațiilor. Garantarea securității mesajelor.*/}
        //

    );

}

export default Benefits;

