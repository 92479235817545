import React from 'react';
import ReactFlagsSelect from "react-flags-select";

function LanguageSelect({ selectedLanguage, onSelectLanguage, className, title }) {
    return (
        <div className={`selectWrapper ${className}`}>
            <ReactFlagsSelect
                className="country-flags"
                selected={selectedLanguage}
                onSelect={onSelectLanguage}
                customLabels={{
                    US: "English",
                    CN: "Chinese",
                    DK: "Danish",
                    NL: "Dutch",
                    FR: "French",
                    DE: "German",
                    IT: "Italian",
                    HU: "Hungarian",
                    JP: "Japanese",
                    PL: "Polish",
                    PT: "Portuguese",
                    RU: "Russian",
                    ES: "Spanish",
                    TR: "Turkish",
                    UA: "Ukrainian",
                    RO: "Română",
                }}
                countries={["us", "cn", "dk", "nl", "fr", "de", "hu", "it", "jp", "pl", "pt", "ru", "es", "tr", "ua", "ro"].map((e) => e.toUpperCase())}
            />
            <span className="myLanguageLabel">
                {title || 'My language'}
            </span>
        </div>
    );
}

export default LanguageSelect;
