import React from 'react';

import maleAvatar from './images/maleAvatar.png';
import femaleAvatar from './images/femaleAvatar.png';

function MessageList({ messages }) {
  return (
    messages.map(({id, message, original, source}) => (
      <div key={id} className={source === "fromLeft" ? "userOneMessage" : "userTwoMessage"} >
        <div className="col-md-10 col-sm-12 messageContent">
          <div className="talk-bubble tri-right btm-right">
            <div className="talktext">
              <p className="textMessage">
                {message}
              </p>
                {original?.trim() !== message?.trim() && (
                    <p className="textMessage">
                        <i>{original}</i>
                    </p>
                )
                }
            </div>
          </div>
          <img src={source === "fromLeft" ? maleAvatar : femaleAvatar} alt="avatar" />
        </div>
      </div>
    ))
  );
}

export default MessageList;
